import { createMask } from "imask";

export const createPhoneMask = () =>
    createMask({
        mask: [
            {
                mask: "(00) 0000-0000",
            },
            {
                mask: "(00) 00000-0000",
            },
            // mask for international numbers, using the E.164 international standard (allowing 13-15 digits)
            // https://en.wikipedia.org/wiki/E.164
            {
                mask: "\\+0000000000000",
            },
            {
                mask: "\\+00000000000000",
            },
            {
                mask: "\\+000000000000000",
            },
        ],
    });
